import React from "react";
import OverlayContainer from "./overlayContainer";
import OverlayInfo from "./overlayInfo";
import Api from "../config/api";
import Exchange from "../config/exchange";
import Util from "../config/util";
import Provider from "../config/provider";
import Common from "../config/common";
import TextArea from "./textArea";
import Button from "./button";
import ProviderAddressRow from "./providerAddressRow";
import ScreenLoad from "./screenLoad";
import * as styles from "./signOverlay.module.css";

export default class SignOverlay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initialising: true,
            processing: false,
            processingText: null,
            processingSubText: null,

            // AUTH | MINT | LAZY_MINT
            type: this.props.type || "AUTH",

            chosen_address: null,
            addresses: this.props.addresses || [],
            sign_by_address: this.props.signBy ? this.props.signBy.toLowerCase() : null,

            connected_wallets: [],
        };
    }

    componentDidMount() {
        this.setup();
    }

    updateProcessingText = (text, subText) => {
        return new Promise((resolve, reject) => {
            this.setState(
                {
                    processingText: text,
                    processingSubText: subText,
                },
                () => {
                    resolve();
                }
            );
        });
    };

    setup = () => {
        this.setState(
            {
                initialising: true,
            },
            () => {
                if (this.state.addresses.length > 0) {
                    for (let i = 0; i < this.state.addresses.length; i++) {
                        Provider.RequestAccounts({
                            providerId: this.state.addresses[i].provider,
                        })
                            .then((accounts) => {
                                if (accounts.length > 0) {
                                    this.setState(
                                        {
                                            connected_wallets: [...this.state.connected_wallets, accounts[0].toLowerCase()],
                                        },
                                        () => {
                                            console.log(this.state);
                                        }
                                    );
                                }
                            })
                            .catch(() => {})
                            .finally(() => {
                                this.setState({
                                    initialising: false,
                                });
                            });
                    }
                } else {
                    this.setState({
                        initialising: false,
                    });
                }
            }
        );
    };

    onSign = async () => {
        let signature = null;
        let error = null;
        if (!this.state.processing) {
            this.setState(
                {
                    processing: true,
                },
                async () => {
                    // Find the correct address
                    const { provider, publicAddress, ...rest } = this.state.sign_by_address
                        ? // If we have a sign by address, use that
                          this.state.addresses.filter((a) => a.publicAddress.toLowerCase() === this.state.sign_by_address.toLowerCase())[0]
                        : // Else use the first of our wallets
                          this.state.addresses.filter((a) => a.publicAddress.toLowerCase() === this.state.connected_wallets[0].toLowerCase())[0];

                    // Check the type of sign we're wanting to achieve - if we're doing a mint/lazy mint then
                    // we need to do a little extra too.
                    switch (this.state.type) {
                        case "MINT":
                            // Get a new signature for minting - this is just a basic auth sig
                            console.log("Mint signing...");
                            await this.updateProcessingText("Requesting a signed message...", "Follow the instructions in the popup window your wallet provider will display.");

                            signature = await Provider.SignMessage({
                                providerId: provider,
                                publicAddress,
                            });

                            // Throw the callback - the signature isn't really required here  so lets not
                            // send it back.
                            await this.props.onSigned(signature);

                            // Run the exchange mint process
                            await this.updateProcessingText("Initiating the mint...", "Follow the instructions in the popup window your wallet provider will display.");
                            error = await Exchange.Mint({
                                providerId: provider,
                                authSignature: signature,
                                from: publicAddress,
                                marketplaceNftContractAddress: this.props.currentChain.marketplaceNftContractAddress,
                                media: this.props.media,
                            });

                            // Throw back the onMinted callback to end the process
                            await this.props.onMinted(error);

                            break;
                        case "LAZY_MINT":
                            // Lazy mint signing
                            console.log("Lazy Mint signing...");
                            await this.updateProcessingText("Requesting a signed message...", "Follow the instructions in the popup window your wallet provider will display.");

                            signature = await Provider.SignMessage({
                                providerId: provider,
                                publicAddress,
                            });

                            // Throw the callback - the signature isn't really required here  so lets not
                            // send it back.
                            await this.props.onSigned(signature);

                            // Run the lazy mint process...
                            await this.updateProcessingText("Initiating the lazy mint...", "Follow the instructions in the popup window your wallet provider will display.");
                            error = await Exchange.LazyMint({
                                providerId: provider,
                                from: publicAddress,
                                mediaId: this.props.media.mediaId,
                            });

                            // Throw back the onMinted callback to end the process
                            await this.props.onMinted(error);

                            break;
                        default:
                            // By default fall to the auth process

                            // Get a new signature for minting - this is just a basic auth sig
                            console.log("Standard auth signing...");
                            signature = await Provider.SignMessage({
                                providerId: provider,
                                publicAddress,
                            });

                            // Throw the callback - the signature isn't really required here  so lets not
                            // send it back.
                            await this.props.onSigned(signature);

                            break;
                    }

                    // // If we were given typed data, use SignTypedData, else use the normal SignMessage call
                    // if (this.props.typedData) {
                    //     console.log(`Signing typed data`);
                    //     Provider.SignTypedData({
                    //         providerId: provider,
                    //         from: publicAddress,
                    //         msgParams: JSON.stringify(this.props.typedData),
                    //     })
                    //         .then(async (signature) => {
                    //             await this.props.onSigned(signature, this.props.typedData.message);
                    //         })
                    //         .catch((e) => {
                    //             console.error("Failed to sign message", { error: e });
                    //             this.setState({
                    //                 processing: false,
                    //                 processing_address: null,
                    //             });
                    //         });
                    // } else {
                    //     console.log(`Signing message`);

                    //     Provider.SignMessage({
                    //         providerId: provider,
                    //         publicAddress,
                    //     })
                    //         .then(async (signature) => {
                    //             await this.props.onSigned(signature);
                    //         })
                    //         .catch((e) => {
                    //             console.error("Failed to sign message", { error: e });
                    //             this.setState({
                    //                 processing: false,
                    //                 processing_address: null,
                    //             });
                    //         });
                    // }
                }
            );
        }
    };

    render() {
        if (this.state.initialising || this.state.processing) {
            return (
                <OverlayContainer header={null} onClose={this.props.onClose}>
                    <OverlayInfo noPadding={true}>
                        <ScreenLoad text={this.state.processingText} subText={this.state.processingSubText} />
                    </OverlayInfo>
                </OverlayContainer>
            );
        } else if (this.state.connected_wallets.length <= 0) {
            return (
                <OverlayContainer header={null} onClose={this.props.onClose}>
                    <OverlayInfo
                        header={<span>Oh No! &#x1F631;</span>}
                        message={
                            this.state.sign_by_address
                                ? `In order to continue we need you to 'Sign' using address ${Common.cropTo(
                                      this.state.sign_by_address,
                                      15
                                  )}, this address is not currently your active wallet though, please change your active wallet to this wallet and we'll try again.`
                                : `In order to continue we need you to 'Sign' using one of your connected addresses, none of your known addresses are currently active though, please change your active wallet and we'll try again.`
                        }
                    >
                        <Button displayMode={1} hoverMode={6} large={true} text={"Try Again"} disabled={this.state.processing} onClick={this.setup} style={{ margin: 0 }} />
                    </OverlayInfo>
                </OverlayContainer>
            );
        } else if (this.state.sign_by_address && this.state.connected_wallets.filter((a) => a.toLowerCase() === this.state.sign_by_address.toLowerCase()).length <= 0) {
            return (
                <OverlayContainer header={null} onClose={this.props.onClose}>
                    <OverlayInfo
                        header={<span>Oh No! &#x1F631;</span>}
                        message={`In order to continue we need you to 'Sign' using address ${Common.cropTo(
                            this.state.sign_by_address,
                            15
                        )}, this address is not currently your active wallet though, please change your active wallet to this wallet and we'll try again.`}
                    >
                        <Button displayMode={1} hoverMode={6} large={true} text={"Try Again"} disabled={this.state.processing} onClick={this.setup} style={{ margin: 0 }} />
                    </OverlayInfo>
                </OverlayContainer>
            );
        } else if (this.state.addresses.filter((a) => this.state.connected_wallets.indexOf(a.publicAddress.toLowerCase()) > -1).length <= 0) {
            return (
                <OverlayContainer header={null} onClose={this.props.onClose}>
                    <OverlayInfo
                        header={<span>Oh No! &#x1F631;</span>}
                        message={`In order to continue we need you to 'Sign' using one of your connected addresses, none of your known addresses are currently active though, please change your active wallet and we'll try again.`}
                    >
                        <Button displayMode={1} hoverMode={6} large={true} text={"Try Again"} disabled={this.state.processing} onClick={this.setup} style={{ margin: 0 }} />
                    </OverlayInfo>
                </OverlayContainer>
            );
        } else {
            return (
                <OverlayContainer header={null} onClose={this.props.onClose}>
                    <OverlayInfo
                        header={<span>Authentication Required</span>}
                        message={`To continue we need to verify your authentication, this is done through a process called 'Signing'. On clicking the 'Sign And Continue' button you will see a sign request from your wallet provider, follow the instructions from your provider to continue.`}
                    >
                        <Button displayMode={1} hoverMode={6} large={true} text={"Sign and Continue"} disabled={this.state.processing} onClick={this.onSign} style={{ margin: 0 }} />
                    </OverlayInfo>
                </OverlayContainer>
            );
        }
    }
}
