import React from "react";
import Web3Utils from "web3-utils";
import { navigate } from "gatsby";
import { withAuthentication } from "../../hoc/withAuthentication";
import * as styles from "./index.module.css";
import Main from "../../components/main";
import Button from "../../components/button";
import Input from "../../components/input";
import TextArea from "../../components/textArea";
import MissingDataPanel from "../../components/missingDataPanel";
import ContentArea from "../../components/contentArea";
import SignOverlay from "../../components/signOverlay";
import MasonryTiles from "../../components/masonryTiles";
import NavPanel from "../../components/navPanel";
import Consts from "../../config/consts";
import Common from "../../config/common";
import Exchange from "../../config/exchange";
import Api from "../../config/api";
import Util from "../../config/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default withAuthentication(
    {
        requiredAuthLevels: [Consts.AUTH_LEVELS.AUTHENTICATED, Consts.AUTH_LEVELS.UNAUTHENTICATED],
        redirectPage: "/",
    },
    class Bids extends React.Component {
        constructor(props) {
            super(props);
            this.listings_section_ref = React.createRef();
            this.bids_section_ref = React.createRef();
            this.activity_section_ref = React.createRef();

            this.state = {
                windowSize: window.innerWidth,
                requiresSigning: false,
                actionListing: null,

                initialising: true,
                listedMedia: [],
                bids: [],
                activity: [],
                isProcessing: false,
            };
        }

        setStateAsync = (newState) => new Promise((resolve) => this.setState(newState, resolve));

        componentDidMount() {
            let resize_timeout = null;
            window.onresize = (val) => {
                clearTimeout(resize_timeout);
                resize_timeout = setTimeout(() => {
                    this.setState({
                        windowSize: window.innerWidth,
                    });
                }, 200);
            };

            // Grab the content
            Promise.all([
                Api.exchange({
                    endpoint: "/listings",
                    method: "GET",
                }),
                Api.exchange({
                    endpoint: "/listings/bids",
                    method: "GET",
                }),
                Api.exchange({
                    endpoint: "/listings/activity",
                    method: "GET",
                }),
            ])
                .then((res) => {
                    this.setState({
                        initialising: false,
                        listedMedia: res[0].data,
                        bids: res[1].bids,
                        activity: res[2].activity,
                    });
                })
                .catch((e) => {
                    this.setState({
                        initialising: false,
                        listedMedia: [],
                        bids: [],
                        activity: [],
                    });
                });
        }

        onHideSignOverlay = () => {
            this.setState({
                requiresSigning: false,
                actionListing: null,
            });
        };

        onRequestStopListing = (listing) => {
            this.setState({
                requiresSigning: true,
                actionListing: listing,
            });
        };
        onStopListingWithSignature = async (signature) => {
            let error = null;
            if (this.state.actionListing.state === "FIXED_PRICE_SALE") {
                error = await Exchange.StopFixedListing({
                    signature: signature,
                    mediaId: this.state.actionListing.media.id,
                });
            } else if (this.state.actionListing.state === "AUCTION_SALE") {
                error = await Exchange.StopAuctionListing({
                    signature: signature,
                    mediaId: this.state.actionListing.media.id,
                });
            }

            if (!error) {
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                this.onHideSignOverlay();
            }
        };

        // Attempts to delete a bid from an auction - note that this can't be done if the auction
        // has ended or is about to end.
        deleteBid = (listingId) => {
            let listing = this.state.listedMedia.filter((l) => l.featuredListing.id === listingId);
            if (listing.length <= 0) {
                Util.notify.error("Unable to find the listing, please refresh your page and try again.");
            } else {
                Api.exchange({
                    endpoint: "/auction/bid",
                    method: "DELETE",
                    data: {
                        listingId,
                    },
                })
                    .then(() => {
                        Util.notify.info(`Successfully deleted your bid for '${listing[0].mediaName}'.`);
                    })
                    .catch(() => {
                        Util.notify.error(`We have been unable to delete your bid for '${listing[0].mediaName}'.`);
                    });
            }
        };

        onClaimWinnings = async (bid) => {
            try {
                await this.setStateAsync({ isProcessing: true });
                console.log(bid);

                // Find the correct address object
                let address = this.props.auth.user.addresses.find((v) => v.publicAddress === bid.bidder.publicAddress);

                const res = await Exchange.RedeemAuction({
                    providerId: address.provider,
                    from: address.publicAddress,
                    exchangeContractAddress: this.props.currentChain.exchangeContractAddress,
                    listingId: bid.media.listingId,
                });

                console.log(res);
            } catch (ex) {
                console.error(ex);
                Util.notify.error(`Failed to redeem winnings.`);
            } finally {
                await this.setStateAsync({ isProcessing: false });
            }
        };

        goToListings = () => {
            window.scrollTo({
                top: this.listings_section_ref.current.offsetTop,
                behavior: "smooth",
            });
        };
        goToBids = () => {
            window.scrollTo({
                top: this.bids_section_ref.current.offsetTop,
                behavior: "smooth",
            });
        };
        goToActivity = () => {
            window.scrollTo({
                top: this.activity_section_ref.current.offsetTop,
                behavior: "smooth",
            });
        };

        render() {
            return (
                <Main
                    title={"BMinted"}
                    initialising={this.state.initialising}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    <span ref={this.listings_section_ref} />
                    <ContentArea header={"Your Listings"} subHeader={`(${this.state.listedMedia.length} listed)`} slim={false} extraTopPadding={true} bottomRule={true}>
                        {this.state.listedMedia.length <= 0 ? (
                            <div className={styles.no_items}>
                                <p className={styles.no_items_header}>No Listings</p>
                                <p className={styles.no_items_message}>You don't currently have any listings. You'll see content here when you start selling your awesome media!</p>
                            </div>
                        ) : (
                            <MasonryTiles
                                data={this.state.listedMedia}
                                //onLoadMore={this.fetchCreated}
                                hasMoreToFetch={false}
                                showLoadMore={false}
                                //loadMoreButtonText={"Load More Created"}
                                listingMode={true}
                                windowSize={this.state.windowSize}
                                showExploreMarket={false}
                                onBid={() => {}}
                                onStopListing={this.onRequestStopListing}
                            />

                            // this.state.listings.map((listing, index) => (
                            //     <TextArea key={`listing_row_${index}`} header={listing.media.name} subHeader={listing.state === "FIXED_PRICE_SALE" ? "Fixed Price" : "Auction"}>
                            //         <div className={styles.listing_container}>
                            //             <div className={styles.listing_image_container}>
                            //                 <img src={`${process.env.GATSBY_STORAGE}media/${listing.media.displayImage}`} />
                            //             </div>
                            //             <div className={styles.listing_info_container}>
                            //                 {listing.state === "FIXED_PRICE_SALE" ? (
                            //                     <p>
                            //                         <span className={styles.info_title}>Price:</span>
                            //                         <b>
                            //                             {listing.fixedPrice} {listing.fixedPriceCoin}
                            //                         </b>
                            //                         <br />
                            //                         <span className={styles.info_title}>Sold:</span>
                            //                         <b>
                            //                             {listing.quantitySold} of {listing.quantity}
                            //                         </b>
                            //                         <br />
                            //                         <span className={styles.info_title}>Bids Received:</span>
                            //                         <b>{listing.bidCount}</b>
                            //                     </p>
                            //                 ) : (
                            //                     <p>
                            //                         <span className={styles.info_title}>Reserve:</span>
                            //                         <b>
                            //                             {listing.auctionReserve} {listing.auctionReserveCoin}
                            //                         </b>
                            //                         <br />
                            //                         <span className={styles.info_title}>Quantity Listed:</span>
                            //                         <b>{listing.quantity}</b>
                            //                         <br />
                            //                         <span className={styles.info_title}>Bids Received:</span>
                            //                         <b>{listing.bidCount}</b>
                            //                         <br />
                            //                         <span className={styles.info_title}>State:</span>
                            //                         {new Date(listing.auctionStartDate) > new Date() ? (
                            //                             <b>Starts {Common.dateString(listing.auctionStartDate)}</b>
                            //                         ) : new Date(listing.auctionEndDate) > new Date() ? (
                            //                             <b>Ends {Common.dateString(listing.auctionEndDate)}</b>
                            //                         ) : (
                            //                             <b>Auction has ended - waiting for winners to claim.</b>
                            //                         )}
                            //                     </p>
                            //                 )}
                            //             </div>
                            //             <div className={styles.listing_action_container}>
                            //                 <Button displayMode={3} hoverMode={4} disabled={false} text={"View Media"} to={`/m/${listing.media.id}`} style={{ margin: 0, marginBottom: 10 }} />
                            //                 {new Date(listing.auctionEndDate) > new Date() && (
                            //                     <Button
                            //                         displayMode={3}
                            //                         hoverMode={4}
                            //                         disabled={false}
                            //                         text={"Stop Listing"}
                            //                         onClick={() => {
                            //                             this.onRequestStopListing(listing);
                            //                         }}
                            //                         style={{ margin: 0 }}
                            //                     />
                            //                 )}
                            //             </div>
                            //         </div>
                            //     </TextArea>
                            // ))
                        )}
                    </ContentArea>

                    <span ref={this.bids_section_ref} />
                    <ContentArea header={"Your Bids"} slim={true} bottomRule={true}>
                        {this.state.bids.length <= 0 ? (
                            <div className={styles.no_items}>
                                <p className={styles.no_items_header}>No Bids</p>
                                <p className={styles.no_items_message}>
                                    Here you can view and manage bids for on going listings or auctions that you've won that need claiming, so keep checking back here if you've been bidding on
                                    auctions!
                                </p>
                            </div>
                        ) : (
                            this.state.bids.map((bid, index) =>
                                bid.listingState === "FIXED_PRICE_SALE" ? (
                                    <TextArea key={`bid_row_${index}`} header={bid.media.name} subHeader={bid.state === "WINNER" ? "Winner" : "Pending"} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                        <div className={styles.bid_container}>
                                            <div className={styles.bid_image_container}>
                                                <img src={`${process.env.GATSBY_STORAGE}media/${bid.media.displayImage}`} />
                                            </div>
                                            <div className={styles.bid_info_container}>
                                                <p>
                                                    <span className={styles.info_title}>Your Bid:</span>
                                                    <b>
                                                        {Web3Utils.fromWei(`${bid.bid.value}`, "ether")} {bid.bid.coin} for {bid.bid.quantity} items.
                                                    </b>
                                                    <br />
                                                </p>

                                                {bid.state === "WINNER" ? (
                                                    <p>
                                                        <span className={styles.info_title}>You've won:</span>
                                                        <b>
                                                            {Web3Utils.fromWei(`${bid.win.value}`, "ether")} {bid.bid.coin} for {bid.win.quantity} items.
                                                        </b>
                                                        <br />
                                                    </p>
                                                ) : (
                                                    <p>Listing hasn't ended yet, please wait until its finished to see if your bid has won.</p>
                                                )}
                                            </div>
                                            <div className={styles.listing_action_container}>
                                                {bid.state === "WINNER" && bid.listingState !== "FIXED_PRICE_SALE" ? (
                                                    <Button
                                                        displayMode={3}
                                                        hoverMode={4}
                                                        disabled={this.state.isProcessing}
                                                        text={"Claim Winnings"}
                                                        onClick={() => {
                                                            this.onClaimWinnings(bid);
                                                        }}
                                                        style={{ margin: 0 }}
                                                    />
                                                ) : (
                                                    <Button displayMode={3} hoverMode={4} disabled={this.state.isProcessing} text={"View Media"} to={`/m/${bid.media.id}`} style={{ margin: 0 }} />
                                                )}
                                            </div>
                                        </div>
                                    </TextArea>
                                ) : (
                                    <TextArea key={`bid_row_${index}`} header={bid.media.name} subHeader={bid.state === "WINNER" ? "Winner" : "Pending"}  style={{ paddingLeft: 0, paddingRight: 0 }}>
                                        <div className={styles.bid_container}>
                                            <div className={styles.bid_image_container}>
                                                <img src={`${process.env.GATSBY_STORAGE}media/${bid.media.displayImage}`} />
                                            </div>
                                            <div className={styles.bid_info_container}>
                                                <p>
                                                    <span className={styles.info_title}>Your Bid:</span>
                                                    <b>
                                                        {Web3Utils.fromWei(`${bid.bid.value}`, "ether")} {bid.bid.coin} for {bid.bid.quantity} items.
                                                    </b>
                                                    <br />
                                                </p>

                                                {bid.state === "WINNER" ? (
                                                    <p>
                                                        <span className={styles.info_title}>You've won:</span>
                                                        <b>
                                                            {Web3Utils.fromWei(`${bid.win.value}`, "ether")} {bid.bid.coin} for {bid.win.quantity} items.
                                                        </b>
                                                        <br />
                                                    </p>
                                                ) : (
                                                    <p>Listing hasn't ended yet, please wait until its finished to see if your bid has won.</p>
                                                )}

                                                <p>{}</p>
                                            </div>
                                            <div className={styles.listing_action_container}>
                                                {bid.state === "WINNER" ? (
                                                    <Button
                                                        displayMode={3}
                                                        hoverMode={4}
                                                        disabled={false}
                                                        text={"Claim Winnings"}
                                                        onClick={() => {
                                                            this.onClaimWinnings(bid);
                                                        }}
                                                        style={{ margin: 0 }}
                                                    />
                                                ) : (
                                                    <Button displayMode={3} hoverMode={4} disabled={false} text={"View Media"} to={`/m/${bid.media.id}`} style={{ margin: 0 }} />
                                                )}
                                            </div>
                                        </div>
                                    </TextArea>
                                )
                            )
                        )}
                    </ContentArea>

                    <span ref={this.activity_section_ref} />
                    <ContentArea header={"Recent Activity"} slim={true}>
                        {this.state.activity.map((activity, index) => (
                            <div key={`activity_line_${index}`} className={styles.activity_container}>
                                <div className={styles.activity_image_container} style={{ backgroundImage: `url("${process.env.GATSBY_STORAGE}media/${activity.media.displayImage}")` }} />
                                <div className={styles.activity_info_container}>
                                    <p>
                                        <span>{Common.dateString(activity.createdAt)}</span>
                                        {activity.state === "ACTIVE" ? (
                                            <span>
                                                You made a bid of {Web3Utils.fromWei(`${activity.bid.value}`, "ether")} {activity.bid.coin} for {activity.bid.quantity} items.
                                            </span>
                                        ) : activity.state === "WINNER" ? (
                                            <span>Your bid won!</span>
                                        ) : activity.state === "CLAIMED" ? (
                                            <span>You claimed your winnings.</span>
                                        ) : activity.state === "REJECTED" ? (
                                            <span>Your bid didn't win this time.</span>
                                        ) : activity.state === "USER_DELETED" ? (
                                            <span>You revoked your bid.</span>
                                        ) : activity.state === "ADMIN_DELETED" ? (
                                            <span>Your bid has been revoked.</span>
                                        ) : (
                                            <span>Unknown state</span>
                                        )}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </ContentArea>

                    <NavPanel
                        showScrollTop={false}
                        tabs={[
                            {
                                onClick: this.goToListings,
                                text: "Listings",
                            },
                            {
                                onClick: this.goToBids,
                                text: "Bids",
                            },
                            {
                                onClick: this.goToActivity,
                                text: "Activity",
                            },
                        ]}
                    />

                    {this.state.requiresSigning && (
                        <SignOverlay
                            addresses={this.props.auth.user.addresses}
                            signBy={this.state.actionListing.owner.publicAddress}
                            onSigned={this.onStopListingWithSignature}
                            onClose={this.onHideSignOverlay}
                            currentChain={this.props.currentChain}
                            chains={this.props.chains}
                        />
                    )}
                </Main>
            );
        }
    }
);
